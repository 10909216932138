.loginMainContainer{
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.loginMainContainer .loginContainer{
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: .5rem;
}
.loginContainer a{
  display: flex;
  border-radius: 5px;
}
.loginContainer a:hover{
  box-shadow: 0 1px 2px 1px rgba(0,0,0, .3);
}
.loginContainer a,
.loginContainer a img{
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
