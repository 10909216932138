@import "../../styles/mixins";

.appContainer {
  display: grid;
  row-gap: 12px;
	column-gap: 12px;
  background-color: #f5f5f5;
  
  .appSection {
    width: 100%;
    min-height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 12px auto;
    padding: 24px;
    gap: 24px;
    border: 1px solid #a9a9a9;
    border-radius: 8px;
    background-color: white;
  }
}

@include mobile {
  .appContainer {
    grid-template-columns: 1fr;
  }
}

@include tablet {
  .appContainer {
    grid-template-columns: 1fr;
  }
}

@include desktop {
  .appContainer {
    grid-template-columns: 1fr 1fr;
  }
}