@import "../../styles/mixins";

.policyContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 12px;
	column-gap: 12px;
  background-color: #f5f5f5;
  
  .policySection {
    display: flex;
    width: 100%;
    min-height: 180px;
    flex-direction: column;
    align-items: stretch;
    padding: 16px;
    border: 1px solid #a9a9a9;
    border-radius: 8px;
    background-color: white;
  }
}

@include mobile {
  .policyContainer {
    grid-template-columns: 1fr;
  }
}

@include tablet {
  .policyContainer {
    grid-template-columns: 1fr 1fr;
  }
}

@include desktop {
  .policyContainer {
    grid-template-columns: 1fr 1fr;
  }
}